.div-in {
  background: #222222;
  border-radius: 10px;
  max-height: 70vh;
  overflow-y: auto;
}

.div-in table {
  width: 100%;
}

.div-in th {
  padding-bottom: 24px !important;
  font-weight: bolder;
  background-color: #222222;
}

.div-in td,
.div-in th {
  padding: 8px;
}

.tr-in {
  color: #969696;
}

.tr-in:hover {
  color: white;
  background: #3d3d3d;
  border-radius: 20px !important;
}

/* InfluencerTab.js */

.influencertab {
  cursor: pointer;
  border-bottom: 0.5px solid #3d3d3d;
}

.influencertab-active {
  cursor: pointer;
  border-bottom: 2px solid #f4e900;
}

.influencertab h6 {
  color: #969696;
}

.influencertab-active h6 {
  color: #f4e900;
}

/* button on detail section */

.active-button {
  padding: 7px 20px;
  background: #8a5fd1;
  border-radius: 30px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border: none;
  color: white;
}

.active-button:hover{
  background: #8b5fd1bb;
}
