.div-admin{
    background: #222222;
    border-radius: 10px; 
    max-height: 75vh;
    overflow-y: auto;
  }
  
  .div-admin table{
    width: 100%;
  }
  
  .div-admin th{
    padding-bottom: 24px !important;
    font-weight: bolder;
    background-color: #222222;
  }
  
  .div-admin td, .div-admin th{
    padding: 8px;
  }
  
  .tr-admin{
    color: #969696;

  }
  
  .tr-admin:hover{
    color: white;
    background:#0000001a ;
    border-radius: 20px !important;
  }

  .td-ra:hover{
    color: #F4E900 !important;
  }