@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&display=swap");

body {
  margin                 : 0;
  font-family            : Nunito, sans-serif !important;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.vh {
  height: 100vh;
}

.vw {
  width: 100vw;
}

.scale {
  transition: transform 500ms ease-in;
}

.scale:hover {
  transform: scale(1.2);
  cursor   : pointer;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3d3d3d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


tr {
  cursor: pointer !important;
}

tr.non-clickable {
  cursor: default !important;
}

.ant-btn-primary {
  color       : #fff !important;
  background  : #393939 !important;
  border-color: #393939 !important;
  text-shadow : 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow  : 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn:hover,
.ant-btn:focus {
  color       : white !important;
  background  : #3d3d3db6 !important;
  border-color: #3d3d3db6 !important;
}

.ant-modal-header,
.ant-modal-close-x {
  display: none !important;
}

.ant-modal-content {
  background: none !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color      : none !important;
  border-right-width: 1px !important;
  outline           : 0;
  box-shadow        : none !important;
  border-bottom     : 1px solid #F4E900;
}

.ant-input:focus {
  outline: 1px solid #F4E900
}

.btn--primary {
  color     : #F4E900 !important;
  background: none !important;
  border    : 0 !important;
}

.btn--primary:hover {
  color: #F4E900 !important;
}

.btn--secondary {
  color     : rgb(138, 95, 209) !important;
  background: none !important;
  border    : 0 !important;
}

.btn--secondary:hover {
  color: rgb(138, 95, 209) !important;
}

.btn--primary.btn--solid {
  color        : #000 !important;
  background   : #F4E900 !important;
  border       : 0 !important;
  border-radius: 20px;
}

.btn--primary.btn--solid:hover,
.btn--primary.btn--solid:active,
.btn--primary.btn--solid:focus {
  color: #000 !important;
}

.btn--secondary.btn--solid {
  color        : #FFF !important;
  background   : rgb(138, 95, 209) !important;
  border       : 0 !important;
  border-radius: 20px;
}

.btn--verified.btn--solid {
  color        : #FFF !important;
  background   : rgb(187, 159, 232) !important;
  border       : 0 !important;
  border-radius: 20px;
}

.btn--danger.btn--solid {
  color        : #FFF !important;
  background   : #900 !important;
  border       : 0 !important;
  border-radius: 20px;
}

.btn--secondary.btn--solid:hover,
.btn--secondary.btn--solid:active,
.btn--secondary.btn--solid:focus {
  color: #FFF !important;
}

.post--card {
  position           : relative;
  width              : 250px;
  padding-top        : 100%;
  border-radius      : 7px;
  background-color   : #212121;
  background-position: center center;
  background-repeat  : no-repeat;
  background-size    : contain;
  box-shadow         : 0px 3px 10px #000;
}