.sidenav {
  height          : 100vh;
  width           : 240px;
  position        : fixed;
  z-index         : 1;
  top             : 0;
  left            : 0;
  background-color: #222222;
  ;
  overflow-x: hidden;
  padding   : 16px 10px;
}

.active .selected {
  padding      : 10px;
  background   : #65459B;
  height       : 46px;
  border-radius: 10px;
  cursor       : pointer;
  position     : relative;
}

.active .selected:after {
  content      : " ";
  position     : absolute;
  right        : 10px;
  top          : 50%;
  transform    : translateY(-50%);
  transform    : translateY(-50%);
  border-left  : 7px solid #FFF;
  border-top   : 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.selected1 {
  padding      : 10px;
  background   : #65459B;
  height       : 46px;
  border-radius: 10px;
  cursor       : pointer;
}

.selected {
  padding      : 10px;
  height       : 46px;
  cursor       : pointer;
  border-bottom: 1px solid #545454;

}

.text-h5 {
  display     : inline-block;
  margin      : none;
  color       : white;
  padding-left: 22px;
  font-size   : 18px;
}

@media screen and (max-width: 1360px) {}


.main-content {
  margin-left: 240px;
  padding    : 40px;
  background : #121212;
  height     : 100vh;
  overflow-x : hidden;
  overflow-y : auto;
}

.active h6 {
  color: #F4E900 !important;
  ;
}