.div-fan{
    background: #222222;
    border-radius: 10px; 
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .div-fan table{
    width: 100%;
  }
  
  .div-fan th{
    padding-bottom: 24px !important;
    font-weight: bolder;
    background-color: #222222;
  }
  
  .div-fan td, .div-fan th{
    padding: 8px;
  }
  
  .tr-fan{
    color: #969696;
  }
  
  .tr-fan:hover{
    color: white;
    background:#3D3D3D ;
    border-radius: 20px !important;
  }


  /* FanTab.js */

.fantab{
  cursor: pointer;
  border-bottom: 0.5px solid #3D3D3D;
  
} 

.fantab-active{
  cursor: pointer;
  border-bottom: 2px solid #F4E900;
}

.fantab h6{
  color:#969696;
}

.fantab-active h6{
  color:#F4E900;

}