.approve-button {
    padding: 7px 20px;
    background: #8a5fd1;
    border-radius: 30px;
    border: none;
    color: white;
  }
  
  .approve-button:hover{
    background: #8b5fd1bb;
  }

  .reject-button {
    padding: 7px 20px;
    background: transparent;
    border-radius: 30px;
    border: 2px solid #8a5fd1;
    color: #8a5fd1;
  }
  
  .reject-button:hover{
    background: #8b5fd1bb;
    color: white;
  }