@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&display=swap);
body {
  margin                 : 0;
  font-family            : Nunito, sans-serif !important;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.vh {
  height: 100vh;
}

.vw {
  width: 100vw;
}

.scale {
  transition: -webkit-transform 500ms ease-in;
  transition: transform 500ms ease-in;
  transition: transform 500ms ease-in, -webkit-transform 500ms ease-in;
}

.scale:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  cursor   : pointer;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3d3d3d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


tr {
  cursor: pointer !important;
}

tr.non-clickable {
  cursor: default !important;
}

.ant-btn-primary {
  color       : #fff !important;
  background  : #393939 !important;
  border-color: #393939 !important;
  text-shadow : 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow  : 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn:hover,
.ant-btn:focus {
  color       : white !important;
  background  : #3d3d3db6 !important;
  border-color: #3d3d3db6 !important;
}

.ant-modal-header,
.ant-modal-close-x {
  display: none !important;
}

.ant-modal-content {
  background: none !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color      : none !important;
  border-right-width: 1px !important;
  outline           : 0;
  box-shadow        : none !important;
  border-bottom     : 1px solid #F4E900;
}

.ant-input:focus {
  outline: 1px solid #F4E900
}

.btn--primary {
  color     : #F4E900 !important;
  background: none !important;
  border    : 0 !important;
}

.btn--primary:hover {
  color: #F4E900 !important;
}

.btn--secondary {
  color     : rgb(138, 95, 209) !important;
  background: none !important;
  border    : 0 !important;
}

.btn--secondary:hover {
  color: rgb(138, 95, 209) !important;
}

.btn--primary.btn--solid {
  color        : #000 !important;
  background   : #F4E900 !important;
  border       : 0 !important;
  border-radius: 20px;
}

.btn--primary.btn--solid:hover,
.btn--primary.btn--solid:active,
.btn--primary.btn--solid:focus {
  color: #000 !important;
}

.btn--secondary.btn--solid {
  color        : #FFF !important;
  background   : rgb(138, 95, 209) !important;
  border       : 0 !important;
  border-radius: 20px;
}

.btn--verified.btn--solid {
  color        : #FFF !important;
  background   : rgb(187, 159, 232) !important;
  border       : 0 !important;
  border-radius: 20px;
}

.btn--danger.btn--solid {
  color        : #FFF !important;
  background   : #900 !important;
  border       : 0 !important;
  border-radius: 20px;
}

.btn--secondary.btn--solid:hover,
.btn--secondary.btn--solid:active,
.btn--secondary.btn--solid:focus {
  color: #FFF !important;
}

.post--card {
  position           : relative;
  width              : 250px;
  padding-top        : 100%;
  border-radius      : 7px;
  background-color   : #212121;
  background-position: center center;
  background-repeat  : no-repeat;
  background-size    : contain;
  box-shadow         : 0px 3px 10px #000;
}
.sidenav {
  height          : 100vh;
  width           : 240px;
  position        : fixed;
  z-index         : 1;
  top             : 0;
  left            : 0;
  background-color: #222222;
  ;
  overflow-x: hidden;
  padding   : 16px 10px;
}

.active .selected {
  padding      : 10px;
  background   : #65459B;
  height       : 46px;
  border-radius: 10px;
  cursor       : pointer;
  position     : relative;
}

.active .selected:after {
  content      : " ";
  position     : absolute;
  right        : 10px;
  top          : 50%;
  -webkit-transform    : translateY(-50%);
          transform    : translateY(-50%);
  transform    : translateY(-50%);
  border-left  : 7px solid #FFF;
  border-top   : 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.selected1 {
  padding      : 10px;
  background   : #65459B;
  height       : 46px;
  border-radius: 10px;
  cursor       : pointer;
}

.selected {
  padding      : 10px;
  height       : 46px;
  cursor       : pointer;
  border-bottom: 1px solid #545454;

}

.text-h5 {
  display     : inline-block;
  margin      : none;
  color       : white;
  padding-left: 22px;
  font-size   : 18px;
}

@media screen and (max-width: 1360px) {}


.main-content {
  margin-left: 240px;
  padding    : 40px;
  background : #121212;
  height     : 100vh;
  overflow-x : hidden;
  overflow-y : auto;
}

.active h6 {
  color: #F4E900 !important;
  ;
}
/* NotificationPanel.js */

.notification-panel {
  background     : rgba(61, 61, 61, 0.38);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  height         : 100vh;
  width          : 360px;
  position       : fixed;
  z-index        : 1;
  top            : 0;
  right          : 0;
  overflow-x     : hidden;
  padding        : 20px;
}

/* NotificationPanel.js End */

/* SearchBar.js  */

input[box='search'] {
  background  : none;
  border      : none;
  padding-left: 10px;
  width       : 360px
}

input[box='search']:focus {
  outline: none;
}

/* SearchBar.js End */

.detail-layout {
  background   : #212121;
  border-radius: 10px;
}

.type-box {
  background   : #292929;
  border-radius: 10px 10px 0px 0px;
}

/* Social link */
.s-icons {
  font-size: 24px;
  margin   : 5px;
  cursor   : pointer;
  color    : white;
}

/* DynamicTable.js */
.div-dynamic {
  background   : #222222;
  border-radius: 10px;
  min-height: 125vh;
}

.div-dynamic table {
  width: 100%;
}

.div-dynamic th {
  padding-bottom: 24px !important;
  font-weight   : bolder;
}

.div-dynamic td,
.div-dynamic th {
  padding: 8px;
}

.tr-dynamic {
  color: #969696;
}


.tr-dynamic:hover {
  background   : #3D3D3D;
  color        : white;
  border-radius: 20px !important;
}

/* DynamicTable.js End */


/* Colors */

.text-purple {
  color: #65459B;
}


/* cursor */

.cursor-pointer {
  cursor: pointer;
}


/* Pagination  */

.pagination {
  margin    : 15px auto;
  display   : flex;
  list-style: none;
  outline   : none;
}

.pagination>.active>a {
  color: yellow !important;
}

.pagination>li>a {
  padding: 5px 10px;
  outline: none;
  cursor : pointer;
  color  : white !important;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: white !important
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset
}

.btn-view{
  border: 1px solid yellow;
  background: none;
  border-radius: 5px;
  color: yellow;
}
.btn-view:hover{
  color: white;
}
.div-in {
  background: #222222;
  border-radius: 10px;
  max-height: 70vh;
  overflow-y: auto;
}

.div-in table {
  width: 100%;
}

.div-in th {
  padding-bottom: 24px !important;
  font-weight: bolder;
  background-color: #222222;
}

.div-in td,
.div-in th {
  padding: 8px;
}

.tr-in {
  color: #969696;
}

.tr-in:hover {
  color: white;
  background: #3d3d3d;
  border-radius: 20px !important;
}

/* InfluencerTab.js */

.influencertab {
  cursor: pointer;
  border-bottom: 0.5px solid #3d3d3d;
}

.influencertab-active {
  cursor: pointer;
  border-bottom: 2px solid #f4e900;
}

.influencertab h6 {
  color: #969696;
}

.influencertab-active h6 {
  color: #f4e900;
}

/* button on detail section */

.active-button {
  padding: 7px 20px;
  background: #8a5fd1;
  border-radius: 30px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border: none;
  color: white;
}

.active-button:hover{
  background: #8b5fd1bb;
}

.div-fan{
    background: #222222;
    border-radius: 10px; 
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .div-fan table{
    width: 100%;
  }
  
  .div-fan th{
    padding-bottom: 24px !important;
    font-weight: bolder;
    background-color: #222222;
  }
  
  .div-fan td, .div-fan th{
    padding: 8px;
  }
  
  .tr-fan{
    color: #969696;
  }
  
  .tr-fan:hover{
    color: white;
    background:#3D3D3D ;
    border-radius: 20px !important;
  }


  /* FanTab.js */

.fantab{
  cursor: pointer;
  border-bottom: 0.5px solid #3D3D3D;
  
} 

.fantab-active{
  cursor: pointer;
  border-bottom: 2px solid #F4E900;
}

.fantab h6{
  color:#969696;
}

.fantab-active h6{
  color:#F4E900;

}
.div-admin{
    background: #222222;
    border-radius: 10px; 
    max-height: 75vh;
    overflow-y: auto;
  }
  
  .div-admin table{
    width: 100%;
  }
  
  .div-admin th{
    padding-bottom: 24px !important;
    font-weight: bolder;
    background-color: #222222;
  }
  
  .div-admin td, .div-admin th{
    padding: 8px;
  }
  
  .tr-admin{
    color: #969696;

  }
  
  .tr-admin:hover{
    color: white;
    background:#0000001a ;
    border-radius: 20px !important;
  }

  .td-ra:hover{
    color: #F4E900 !important;
  }
.react-daterange-picker__wrapper {
    background-color: white;
    margin-left: 23px;
    height: 33px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: thin solid gray;
}
.td:hover{
    color: #F4E900;
}
.approve-button {
    padding: 7px 20px;
    background: #8a5fd1;
    border-radius: 30px;
    border: none;
    color: white;
  }
  
  .approve-button:hover{
    background: #8b5fd1bb;
  }

  .reject-button {
    padding: 7px 20px;
    background: transparent;
    border-radius: 30px;
    border: 2px solid #8a5fd1;
    color: #8a5fd1;
  }
  
  .reject-button:hover{
    background: #8b5fd1bb;
    color: white;
  }
