/* NotificationPanel.js */

.notification-panel {
  background     : rgba(61, 61, 61, 0.38);
  backdrop-filter: blur(10px);
  height         : 100vh;
  width          : 360px;
  position       : fixed;
  z-index        : 1;
  top            : 0;
  right          : 0;
  overflow-x     : hidden;
  padding        : 20px;
}

/* NotificationPanel.js End */

/* SearchBar.js  */

input[box='search'] {
  background  : none;
  border      : none;
  padding-left: 10px;
  width       : 360px
}

input[box='search']:focus {
  outline: none;
}

/* SearchBar.js End */

.detail-layout {
  background   : #212121;
  border-radius: 10px;
}

.type-box {
  background   : #292929;
  border-radius: 10px 10px 0px 0px;
}

/* Social link */
.s-icons {
  font-size: 24px;
  margin   : 5px;
  cursor   : pointer;
  color    : white;
}

/* DynamicTable.js */
.div-dynamic {
  background   : #222222;
  border-radius: 10px;
  min-height: 125vh;
}

.div-dynamic table {
  width: 100%;
}

.div-dynamic th {
  padding-bottom: 24px !important;
  font-weight   : bolder;
}

.div-dynamic td,
.div-dynamic th {
  padding: 8px;
}

.tr-dynamic {
  color: #969696;
}


.tr-dynamic:hover {
  background   : #3D3D3D;
  color        : white;
  border-radius: 20px !important;
}

/* DynamicTable.js End */


/* Colors */

.text-purple {
  color: #65459B;
}


/* cursor */

.cursor-pointer {
  cursor: pointer;
}


/* Pagination  */

.pagination {
  margin    : 15px auto;
  display   : flex;
  list-style: none;
  outline   : none;
}

.pagination>.active>a {
  color: yellow !important;
}

.pagination>li>a {
  padding: 5px 10px;
  outline: none;
  cursor : pointer;
  color  : white !important;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: white !important
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset
}

.btn-view{
  border: 1px solid yellow;
  background: none;
  border-radius: 5px;
  color: yellow;
}
.btn-view:hover{
  color: white;
}